<template>
    <div>
        <Panel :toggleable="true">
            <template #header>
                <span class="font-bold">CUENTA</span>
            </template>
            <div class="col-12 card mt-4">
                <form @submit.prevent="updateUser">
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-6 lg:col-6 pt-4">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_last_name" 
                                    ref="user_last_name" 
                                    v-model="user.last_name" 
                                    autofocus tabindex="1" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_last_name">Apellido</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-6 lg:col-6 pt-4">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_first_name" 
                                    ref="user_first_name" 
                                    v-model="user.first_name" 
                                    tabindex="2" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_first_name">Nombre</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-6 lg:col-6 pt-4">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_email" 
                                    ref="user_email" 
                                    v-model="user.email" 
                                    tabindex="3" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_email">Email</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-6 lg:col-6 pt-4">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_phone_number" 
                                    ref="user_phone_number" 
                                    v-model="user.phone_number" 
                                    tabindex="4" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_phone_number">Telefono</label>
                            </span>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="col-12 text-right">
                            <Button
                                label="
                                ACTUALIZAR"
                                tabindex="5"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Panel>
    </div>
</template>

<script>

    import apiService from '../../services/apiService.js'

    export default {
        name: 'Account',
        data(){
            return{
                isLoading: false,
                user: {
                    id: null,
                    last_name: null,
                    first_name: null,
                    email: null,
                    phone_number: null,
                }
            }
        },
        async mounted(){
            this.user = this.$store.state.user;
        },
        methods: {
            async updateUser(){
                this.$confirm.require({
                    message: 'Está seguro de que desea continuar?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.isLoading = true

                        this.isLoading = true
                        apiService.User.updateProfile(this.user)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.$store.commit('setUserData', this.user);
                                this.FormSuccess(result.message)
                                this.isLoading = false
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        })
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            }
        }
    }

</script>