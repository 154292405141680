<template>
    <div class="col-12 card mt-3">

        <TabView lazy :activeIndex="activeIndex">
            <TabPanel>
                <template #header>
                    <i class="fas fa-cog"></i>
                    <span class="pl-1">Cuenta</span>
                </template>
                <Account/>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="fas fa-lock"></i>
                    <span class="pl-1">Seguridad</span>
                </template>
                <Security/>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>

    import Account from '../Account/Account.vue'
    import Security from '../Account/Security.vue'

    export default {
        name: 'IndexAccount',
        components:{
            Account,
            Security,
        },
        data(){
            return{
                activeIndex: 1,
                isLoading: false,
            }
        },
    }

</script>
