<template>
    <div>
        <Panel :toggleable="true">
            <template #header>
                <span class="font-bold">DATOS DE ACCESO</span>
            </template>
            <div class="col-12 card mt-4">
                <form>
                    <div class="formgrid grid">

                        <div class="field col-12 sm:col-12 md:col-12 lg:col-4">
                            <label for="user_username">Nombre de Usuario</label>
                            <div class="p-inputgroup">
                                <InputText 
                                    type="text" 
                                    id="user_username" 
                                    ref="user_username" 
                                    v-model="user.username" 
                                    autofocus tabindex="1" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <Button
                                    class="p-button-success"
                                    icon="fas fa-save"
                                    label="GUARDAR"
                                    tabindex="2"
                                    @click="updateUser('username')"
                                />
                            </div>
                        </div>

                        <div class="field col-12 sm:col-6 md:col-12 lg:col-2"></div>

                        <div class="field col-12 md:col-6 lg:col-3">
                            <label for="user_password">Nueva Clave</label>
                            <InputText 
                                type="password" 
                                id="user_password" 
                                ref="user_password" 
                                v-model="user.password" 
                                tabindex="3" 
                                placeholder="******"
                                class="inputfield w-full"
                                autocomplete="off"
                            />
                        </div>
                        
                        <div class="field col-12 md:col-6 lg:col-3">
                            <label for="user_password_confirmation">Confirmar Clave</label>
                            <div class="p-inputgroup">
                                <InputText 
                                    type="password" 
                                    id="user_password_confirmation" 
                                    ref="user_password_confirmation" 
                                    v-model="user.password_confirmation" 
                                    tabindex="4" 
                                    placeholder="******"
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <Button
                                    class="p-button-success"
                                    icon="fas fa-save"
                                    label="GUARDAR"
                                    tabindex="5"
                                    @click="updateUser('password')"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Panel>

        <br>

        <Panel :toggleable="true">
            <template #header>
                <span class="font-bold">SESIONES ACTIVAS</span>
            </template>

            <div class="col-12 card">
                <h5 class="text-center">NO SE REGISTRAN DATOS</h5>
            </div>
        </Panel>

        <br>

        <Panel :toggleable="true">
            <template #header>
                <span class="font-bold">LOGS DE ACCESO</span>
            </template>

            <div class="col-12 card">
                <DataTable 
                    :value="logs" 
                    responsiveLayout="scroll" 
                    :rowHover="true"
                    v-model:filters="filters" 
                    filterDisplay="row"
                    :globalFilterFields="['id','action', 'address', 'device']"
                >
                    <template #header>
                        <div class="flex justify-content-start">
                            
                            <span class="p-input-icon-left ">
                                <i class="fas fa-search" />
                                <InputText v-model="filters['global'].value" class="ml-2" placeholder="Filtrar Datos" />
                            </span>
                        </div>
                    </template>

                    <template #empty>{{ strings.es.dataTable.empty }}</template>
                    <template #loading>{{ strings.es.dataTable.loading }}</template>

                    <Column 
                        field="id" 
                        header="ID" 
                        :sortable="true" 
                        :style="{width:'50px'}"></Column>

                    <Column 
                        field="user.username" 
                        header="Usuario" 
                        :sortable="true" 
                        :style="{width:'50px'}"></Column>


                    <Column 
                        field="action" 
                        :sortable="true" 
                        header="Acción"></Column>
                    
                    <Column 
                        field="address"
                        header="Dirección IP" 
                        :sortable="true"></Column>

                    <Column 
                        field="device"
                        header="Dispositivo" 
                        :sortable="true"></Column>
                    
                    <Column 
                        field="created_at"
                        header="Creado"
                        :style="{width:'150px'}"
                        :sortable="true">
                        <template #body="row">{{ (row.data.created_at != null) ? $moment.utc(row.data.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") : '-' }}</template>
                    </Column>
                </DataTable>
            </div>
        </Panel>
    </div>
</template>

<style scoped>
    :deep(.p-button-label){
        width: 100px;
        padding: 0px !important;
        margin: 0px !important;
    }
    :deep(.p-button-success){
        background: #2ca356;
    }
</style>

<script>

    import apiService from '../../services/apiService.js'
    import { FilterMatchMode } from 'primevue/api';

    export default {
        data(){
            return{
                isLoading: false,
                user: {
                    id: this.$store.state.user.id,
                    first_name: this.$store.state.user.first_name,
                    last_name: this.$store.state.user.last_name,
                    username: this.$store.state.user.username,
                    password: null,
                    password_confirmation: null,
                    email: this.$store.state.user.email,
                    phone_number: this.$store.state.user.phone_number,
                    type: null
                },
                logs: [],
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'user.username': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'action': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'address': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'device': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                },
            }
        },
        mounted(){
            this.getLogs()
        },
        methods: {
            async updateUser(type){
                this.$confirm.require({
                    message: 'Está seguro de que desea continuar?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.isLoading = true
                        this.user.type = type
                        apiService.User.updateCredentials(this.user)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.$store.commit('setUserData', this.user);
                                this.FormSuccess(result.message)
                                this.isLoading = false
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        })
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            async getLogs(){
                apiService.User.logs(this.$store.state.user.id)
                .then((result) => {
                    this.logs = result
                })
            },
        }
    }

</script>